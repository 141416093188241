import './index.scss'

const Logo = () => {
  return (
    <div className="logo-container">
      <svg
        className="frame-logo"
        width="559pt"
        height="897pt"
        version="1.0"
        viewBox="0 0 131.1 249.7"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="svg-container" fill="none">
          <path className='frame.logo'
            d="M87.9,218.3L67.2,207l32.2-4.5l20.7,11.3L87.9,218.3z M87.8,134.7l-20.7-11.3l0.1-34.1l20.7,11.3L87.8,134.7z
	 M54.9,105.2L34.2,93.9l33-4.6l20.7,11.3L54.9,105.2z M54.9,105L34.2,93.7l33-4.6l20.7,11.3L54.9,105z M87.9,100.6L67.2,89.3
	l0.2-84.1l20.7,11.3L87.9,100.6z M54.8,139.1l-20.7-11.3l33-4.6l20.7,11.3L54.8,139.1z M120.1,213.8l-20.7-11.3L99.7,0.8l20.7,11.3
	L120.1,213.8z M87.9,100.6l-0.1,33.9l-33,4.6l0.1-33.9L87.9,100.6z M87.9,100.4v0.2l-33,4.6l-0.1,33.9l33-4.6v0.2l-33,4.6l-0.2,0
	l0.1-34.3l0.2,0L87.9,100.4z M120.4,12.1l-0.4,201.7l-32.2,4.5l0.4-201.7L120.4,12.1z M88.3,16.6L67.5,5.4l32.2-4.5l20.7,11.3
	L88.3,16.6z M88.1,16.5l32.6-4.6L120.3,214l-32.6,4.6l0.2-83.9l0.1-34.1L88.1,16.5C88.1,16.5,88.1,16.5,88.1,16.5z M120.1,213.8
	l0.4-201.7l-32.2,4.5l-0.4,201.7L120.1,213.8 M67.5,5.4l-0.2,83.8l20.5,11.2v0.2l-0.1,33.9v0.2l-20.6,2.9L67.2,207l20.7,11.3
	l0.4-201.7L67.5,5.4z M67.1,137.6l-0.1,69.7l20.7,11.3l0.2-83.9l0,0L67.1,137.6z M22.3,227.5L1.6,216.3l32.2-4.5L54.5,223
	L22.3,227.5z M54.5,223l-20.7-11.3l0.2-83.7l20.7,11.3L54.5,223z M54.8,139.1l-20.7-11.3l0.1-33.9l20.7,11.3L54.8,139.1z M54.7,105
	L34,93.7l0.2,0L54.9,105L54.7,105z M54.6,139.3l-20.7-11.3L34,93.7L54.7,105L54.6,139.3z M88.1,16.5L67.3,5.2l32.6-4.6l20.7,11.3
	L88.1,16.5z M54.9,105L34.2,93.7l0-83.7l20.7,11.3L54.9,105z M22.7,25.9L1.9,14.6l32.2-4.5l20.7,11.3L22.7,25.9z M54.9,105l0-83.7
	l-32.2,4.5l-0.4,201.7l32.2-4.5l0.1-83.7l0,83.9l-32.6,4.6l0.4-202.1l32.6-4.6L54.9,105z M22.3,227.5L1.6,216.3L1.9,14.6l20.7,11.3
	L22.3,227.5z M54.9,21.3L54.5,223l-32.2,4.5l0.4-201.7L54.9,21.3z M22.1,227.8L1.4,216.5L1.7,14.4l20.7,11.3L22.1,227.8z M22.5,25.7
	L1.7,14.4l32.6-4.6l20.7,11.3L22.5,25.7z"
          />
        </g>
      </svg>
    </div>
  )
}

export default Logo
