import { useState } from 'react'
import { Link } from 'react-router-dom'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import Logo from './Logo'
import Loader from 'react-loaders'

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const hiArray = Array.from('Hi,')
  const imArray = Array.from("I'm ")
  const nameArray = Array.from('Harry, a')
  const jobArray = Array.from("Web developer")

  setTimeout(() => {
    setLetterClass('text-animate-hover')
  }, 4000)
  return (
    <>
    <div className="container home-page">
      <div className="text-zone">
        <h1>
          <AnimatedLetters
            letterClass={letterClass}
            strArray={hiArray}
            idx={-2}
          />
          <br />
          <AnimatedLetters
            letterClass={letterClass}
            strArray={imArray}
            idx={hiArray.length + 4}
          />
          <AnimatedLetters
            letterClass={letterClass}
            strArray={nameArray}
            idx={imArray.length + hiArray.length + 4}
          />
          <br />
          <AnimatedLetters
            letterClass={letterClass}
            strArray={jobArray}
            idx={nameArray.length + hiArray.length + imArray.length + 4}
          />
        </h1>
        <h2>Web developer | Customer Support | Data Analyst</h2>
        <Link to="/contact" className="flat-button">
          CONTACT ME
        </Link>
      </div>
      <Logo />
    </div>
    <Loader type='cube-transition'/>
    </>
  )
}

export default Home
