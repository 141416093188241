import Loader from 'react-loaders'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useState } from 'react'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import { MapContainer, TileLayer, Marker} from 'react-leaflet'


const Contact = () => {
  const titleArray = Array.from('Contact Me')
  const [letterClass, setLetterClass] = useState('text-animate')
  const refForm = useRef()
  setTimeout(() => {
    setLetterClass('text-animate-hover')
  }, 4000)

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_onocobx',
        'template_y7pgpzh',
        refForm.current,
        'ipNGQe78Zkkyi-brZ'
      )
      .then(
        () => {
          alert('Message successfully sent!')
          window.location.reload(false)
        },
        () => {
          alert('Failed to send the message, please try again.')
        }
      )
  }
  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={titleArray}
              idx={15}
            />
          </h1>
          <p>
            As an individual seeking entry-level employment, I am actively
            seeking freelance opportunities, as well as temporary or full-time
            positions. If you have any inquiries or would like to discuss
            potential opportunities, please do not hesitate to contact me
            through the form below.
          </p>
          <div className="contact-form">
            <form ref={refForm} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input type="text" name="name" placeholder="Name" required />
                </li>
                <li className="half">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                  />
                </li>
                <li>
                  <input
                    placeholder="Subject"
                    type="text"
                    name="subject"
                    required
                  />
                </li>
                <li>
                  <textarea
                    placeholder="Message"
                    name="message"
                    required
                  ></textarea>
                </li>
                <li>
                  <input type="submit" className="flat-button" value="SEND" />
                </li>
              </ul>
            </form>
          </div>
        </div>
        <div className='info-map'>
            Harrison Murrihy
            <br />
            Melbourne, Australia
            <br />
            <span>harrymurrihy@gmail.com</span>
        </div>
        <div className="map-wrap">
          <MapContainer center={[-37.81462, 144.96726]} zoom={13}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[-37.81462, 144.96726]}>
            </Marker>
          </MapContainer>
        </div>
      </div>
      <Loader type="cube-transition" />
    </>
  )
}

export default Contact
